import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import { sessionAtom } from '../../atoms/sessionAtom';
import loginAction from '../../actions/loginActions';
import './header.css';

const DashboardHeader = () => {
  const [user] = useRecoilState(userAtom);
  const [session, setSession] = useRecoilState(sessionAtom);

  const exit = () => {
    const newSession = {...session};
    newSession.requireValidation = false;
    newSession.logoff = true;
    setSession(newSession);
    loginAction.quit();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " id="navHeader">
      <div className="container-fluid">
        <div className="collapse navbar-collapse justify-content-end">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link className="text-blue forced-link header-action" to="/profile" >
                <i className="material-icons">person</i>
                <span>{user.userName}</span>
              </Link>
            </li>
            <li>
              <a className="text-blue forced-link header-action" onClick={exit}>
                <i className="material-icons">exit_to_app</i>
                <span>Sair</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default DashboardHeader;
