const config = {
  connectToApi: true,
  color: 'selectedMenu',
  administrationColor: 'adminMenu',

  menuItems: [{
    id: 1,
    active: true,
    href: '/sign',
    icon: 'mail',
    name: 'Central de notificação',
    onlyWsRM: true
  }, {
    id: 2,
    active: true,
    href: '/position',
    icon: 'add_reaction',
    name: 'Colaboradores',
    onlyWsRM: true
  },{
    id: 3,
    active: true,
    href: '/parameter',
    icon: 'settings',
    name: 'Parametrizar Coligada',
    onlyWsRM: true
  },{
    id: 4,
    active: true,
    href: '/positionConfig',
    icon: 'settings_applications',
    name: 'Fluxos de Posição',
    onlyWsRM: true
  },{
    id: 5,
    active: true,
    href: '/scheduler',
    icon: 'today',
    name: 'Agendamentos',
    onlyWsRM: true
  }, {
    id: 6,
    active: true,
    href: '/generalParams',
    icon: 'build_circle',
    name: 'Parametros Gerais',
    onlyWsRM: true
  }
]
};

export default config;