import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './menu.css';

const MenuItem = ({ item, color }) => {
  return (
    <li id='menuItem' key={item.id} className='nav-item' >
      <NavLink exact to={item.href} replace className='nav-link' activeClassName={color} >
        <i className="material-icons" >{item.icon}</i>
        <p>{item.name}</p>
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

export default MenuItem;
