import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import ReactDOM from 'react-dom';
import Start from './start';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css?v=1.2.6';
import './material-dashboard.min.css?v=1.2.6';
import '../node_modules/toastr/build/toastr.min.css';
import './index.css';


ReactDOM.render(
  <React.StrictMode> 
    <RecoilRoot>
      <RecoilNexus />
      <Start />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
