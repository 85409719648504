const messages = {};

messages.ErrMultiplasSecoes = 'O Guardian não permite múltiplas sessões por questões de segurança. '
  + 'Favor encerrar todas a sessões abertas e fazer o acesso novamente';
messages.ErrClientNotFound = 'A empresa informada não foi localizada, favor verificar se a URL está correta';
messages.ErrApiNotFound = 'Não foi possível estabelecer uma conexão com a API.'
  + ' Por favor tente mais tarde ou contacte o administrador do sistema';
messages.ErrCaptcha = 'Por favor confirme que você não é um robô.';
messages.ErrAuthSaml = 'Erro de autenticação, tente novamente mais tarde ou procure o administrador.';

export default messages;
