import React from 'react';
import PropTypes from 'prop-types';
import Menu from './menu/Menu';
import Header from './common/Header';
import Footer from './common/Footer';
import config from '../config';
import services from '../api/services/permissionsServices';
import servicesParam from '../api/services/ntcParameterService';

class App extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      fullPage: false,
      childState: null,
      menu: config.menuItems,
      menuPermissions: {},
      ntcParametro: {}
    };
  }

  showMenu = () => {
    if (!this.state.fullPage) {
      const obj = Object.assign([], this.state.menu);
      obj.forEach(element => {
        if (element.id === 1)
          element.active = this.state.menuPermissions.allowNotification;
        if (element.id === 2)
          element.active = this.state.menuPermissions.allowOnboarding;

      });
      return <Menu items={obj.filter(e => e.active === true)} color={config.color} />;
    }
  }

  componentDidMount = () => {
    services.get().then(res => {
      if (res && res.data) {
        const menuPermissions = res.data;
        this.setState({ menuPermissions });
      }
    });

    servicesParam.get().then(res => {
      if (res && res.data) {
        const ntcParametro = res.data;
        this.setState({ ntcParametro });
      }
    });
  }

  showHeader = () => {
    if (!this.state.fullPage) {
      return <Header />;
    }
  }

  showFooter = () => {
    if (!this.state.fullPage) {
      return <Footer />;
    }
  }

  render = () => {
    return (
      <div className='wrapper'>
        {this.showMenu()}
        <div className='main-panel'>
          {this.showHeader()}
          {this.props.children}
          {this.showFooter()}
        </div>
      </div>
    );
  }
}

export default App;
