import React from 'react';
import Routes from './routes';
import Login from './components/login/LoginPage';
import clientServices from './actions/clientActions';
import messages from './commons/constMessages';
import { useRecoilState } from 'recoil';
import { userAtom } from './atoms/userAtom';

const Start = () => {
  const [user] = useRecoilState(userAtom);

  const getComponent = () => {
    let errorMessage = '';
    if (user.isAuth && !clientServices.persistEndpoint(user)) {
      errorMessage = messages.ErrMultiplasSecoes;
    } 

    const component = user.isAuth && !errorMessage ? <Routes /> : <Login errorMessage={errorMessage} />;
    return component;
  };

  return (getComponent());
};

export default Start;
