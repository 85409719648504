import React from 'react';
import logo from '../../assets/img/LogoGuardianNova-h.png';
import { useHistory } from "react-router-dom";

const MenuLogo = () => {
  let history = useHistory();

  return (
    <div className="logo">
      <center>
        <img src={logo} alt="Guardian" width="155" onClick={() => history.push("/welcome")} />
      </center>
    </div>
  );
};

export default MenuLogo;
