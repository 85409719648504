import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'hreNtcParameter';

service.get = () => {
  return apiUtils.exec(serviceName + '/getParameter');
};


export default service;