import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from './components/AsyncComponent';
import App from './components/App';

const Sign = asyncComponent(() => import('./components/sign/SignPage'));
const SignManager = asyncComponent(() => import('./components/sign/SignManager'));
const ProcessSign = asyncComponent(() => import('./components/processSign/ProcessSignPage'));
const NtcDashPage = asyncComponent(() => import('./components/ntcHistory/NtcDashPage'));
const Position = asyncComponent(() => import('./components/position/PositionPage'));
const PositionConfig = asyncComponent(() => import('./components/poisitionConfig/PositionConfigPage'));
const WelcomePage = asyncComponent(() => import('./components/welcome/WelcomePage'));
const SignDetailPage = asyncComponent(() => import('./components/sign/SignDetailPage'));
const Parameter = asyncComponent(()=> import('./components/administration/ParameterPage'));
const DgParameter = asyncComponent(()=> import('./components/administration/DgParameter'));
const StepPage = asyncComponent(()=> import('./components/administration/StepPage'));
const StepManager = asyncComponent(()=> import('./components/administration/StepManager'));
const DgStepDoc = asyncComponent(()=> import('./components/administration/PageStepDoc'));
const StepInfoManager = asyncComponent(()=> import('./components/administration/StepInfoManager'));
const SchedulerPage = asyncComponent(()=> import('./components/scheduler/SchedulerPage'));
const SchedulerEdit = asyncComponent(()=> import('./components/scheduler/SchedulerEdit'));
const GeneralParamsPage = asyncComponent(()=> import('./components/generalParams/GeneralParamsPage'));

const Routes = () => (
  <HashRouter>
    <div>
      <App>
        <Switch>
          <Route exact path='/sign' component={Sign}/>
          <Route path='/sign/:id' component={SignManager}/>
          <Route exact path='/processSign/:id' component={ProcessSign}/>
          <Route exact path='/history/:id' component={NtcDashPage}/>
          <Route exact path='/position' component={Position}/>
          <Route exact path='/positionConfig' component={PositionConfig}/>
          <Route exact path='/welcome' component={WelcomePage}/>
          <Route exact path='/parameter' component={Parameter}/>
          <Route exact path='/parameter/:id' component={DgParameter}/>
          <Route exact path='/stepPage/:id' component={StepPage}/>
          <Route exact path='/signDetail/:id' component={SignDetailPage}/>
          <Route exact path='/scheduler' component={SchedulerPage}/>
          <Route exact path='/scheduler/:id' component={SchedulerEdit}/>
          <Route exact path='/company/:id/step/:idStep/StepCompany/:idStepCompany' component={StepManager}/>
          <Route exact path='/doc/company/:id/step/:idStep/StepCompany/:idStepCompany' component={DgStepDoc}/>
          <Route exact path='/info/company/:id/step/:idStep/StepCompany/:idStepCompany' component={StepInfoManager}/>
          <Route exact path='/generalParams' component={GeneralParamsPage}/>
          <Redirect from='*' to='/welcome'/>
        </Switch>
      </App>
    </div>
  </HashRouter>
);

export default Routes;

