import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">       
        <p className="copyright pull-right">
          <a href="http://www.guardianrh.com.br">Guardian</a> &copy;{new Date().getFullYear()}. Desenvolvido por profissionais de RH para profissionais de RH
        </p>
      </div>
    </footer>
  );
};

export default Footer;
