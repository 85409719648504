const actions = {};

actions.resolveEndpoint = () => {
  const ENDPOINT_URL_INDEX = 3;
  const urlParts = window.location.href.split('/');
  const endPoint = urlParts.length < ENDPOINT_URL_INDEX || !urlParts[ENDPOINT_URL_INDEX] || urlParts[ENDPOINT_URL_INDEX] === '#' ? 'default' : urlParts[ENDPOINT_URL_INDEX];
  return endPoint;
};

actions.persistEndpoint = user => {
  return user.endPoint === actions.resolveEndpoint();
};

export default actions;