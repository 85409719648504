import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'permissions';

service.get = () => {
  return apiUtils.exec(serviceName + '/');
};

export default service;
